<template>
  <a-modal
      title="修改状态"
      :visible="visible"
      :maskClosable="false"
      destroyOnClose
      :confirm-loading="confirmLoading"
      @cancel="handleCancel"
    >
      <a-form :form="form" :label-col="{ span: 4 }" :wrapper-col="{ span: 18 }">
        <a-form-item label="状态">
          <a-select placeholder="请选择状态"
                v-decorator="[
                    'status',
                    {rules: [{ message: '请选择状态'}]}
                  ]">
                <a-select-option value="0">待开始</a-select-option>
                <a-select-option value="1">进行中</a-select-option>
                <a-select-option value="2">已完成</a-select-option>
              </a-select>
        </a-form-item>
      </a-form>
      <span slot="footer" class="dialog-footer">
        <a-button @click="visible = false">取消</a-button>
        <a-button class="ant-btn ant-btn-primary" :loading="confirmLoading" @click="dataFormSubmit()">确定</a-button>
      </span>
    </a-modal>
</template>
<script>
import { updateStatus } from "@/httpsAPI/common";
    export default {
        data(){
            return {
                form: this.$form.createForm(this),
                visible: false,
                confirmLoading: false,
                id:'',
                type:null, // （类型(1：计划(项目阶段，评审点)，2：项目，3：任务 4:求助单）
            }
        },
        components:{
        },
        methods:{
          dataFormSubmit(e) {
            this.form.validateFields((err, values) => {
                if (err) {
                    return;
                }
                this.confirmLoading = true;
                
                values['id'] = this.id
                values['type'] = this.type

                console.log('Received values of form: ', values);
                updateStatus(values).then(res=>{
                  if(res.code==10000) {
                    this.$message.success({
                        content: '操作成功',
                        duration: 1,
                        onClose: () => {
                            this.confirmLoading = false;
                            this.form.resetFields();
                            this.visible = false;
                            this.$emit('refreshDataList')
                        }
                    })
                  }else {
                    this.$message.error({
                        content: res.msg,
                        duration: 1
                    })
                  }
                })
            });
          },
          handleCancel(e) {
            this.visible = false;
          },
          init (id,type,status) {
            this.visible = true
            this.confirmLoading = false
            this.id = id 
            this.type = type
            this.$nextTick(() => { 
                this.form.resetFields();
                this.form.setFieldsValue({
                    status:status + ''
                })
            });
          }
        }
    }
</script>