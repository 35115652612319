<template>
  <a-modal
      title="处理详情"
      :width="720"
      :visible="visible"
      :confirm-loading="confirmLoading"
      @cancel="handleCancel"
    >
      <a-form :form="form" :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }">
        <a-form-item label="处理意见">
          {{remark}}
        </a-form-item>

        <a-form-item label="附件">
          <a-button type="link" v-if="filePath" @click="downloadFile">{{oldFileName}}</a-button>
        </a-form-item>
      </a-form>
      <span slot="footer" class="dialog-footer">
        <a-button @click="visible = false">关闭</a-button>
      </span>
    </a-modal>
</template>
<script>
import { downloadCommonFile } from "@/httpsAPI/knowledgeBase/index"
import { coludDownloadFile } from '@/httpsAPI/common'
    export default {
        data(){
            return {
                form: this.$form.createForm(this),
                visible: false,
                confirmLoading: false,
                filePath : '',
                fileId : 0,
                remark: '',
                oldFileName:''
            }
        },
        created(){
          
        },
        methods:{
          handleCancel(e) {
            this.visible = false;
          },
          init (record) {
            this.visible = true
            this.$nextTick(() => { 
                this.form.resetFields();
                // this.filePath = record.filePath.substring(record.filePath.lastIndexOf('/')+1)
                this.filePath = record.filePath
                this.fileId = record.fileId
                this.remark = record.remark
                this.oldFileName = record.oldFileName
            });
          },
          //附件下载
          // downloadFile () {
          //   const params = {
          //     id: [this.fileId],
          //     fileName: this.fileName
          //   }
          //   downloadCommonFile(params).then(res => {
          //   })
          // },

          //附件下载
         downloadFile (record) {
            var type = window._CONFIG['downloadType'];
              if (type == "local") {
              const params = {
                id: [this.fileId],
                fileName: record.fileName + this.filePath.substring(this.filePath.lastIndexOf('.'))
              }
                downloadCommonFile(params).then(res => {
                })
              }
              if(type == "cloud"){
                  coludDownloadFile(this.fileId,'/project/commonFile/file-download');
              }
          },
        }
    }
</script>