<template>
  <div style="background:#fff;height:858px">
  <a-card
    title="项目动态"
    style="width: 100%; margin-top: 20px; position: relative;height:808px;"
    @click="onClose"
  >
    <div slot="extra">
      <a-radio-group default-value="" @change="changeTab">
        <a-radio-button v-for="item in tabOptions" :key="item.key" :value="item.value" > {{item.label}} </a-radio-button>
      </a-radio-group>
    </div>
    <div class="comment-top">
      <h4 v-if="trendsInfo.length" style="display: inline-block">共{{trendsInfo.length}}条</h4>
      <div
        v-infinite-scroll="handleInfiniteOnLoad"
        class="demo-infinite-container"
        :infinite-scroll-disabled="busy"
        :infinite-scroll-distance="10"
      >
        <a-list v-if="trendsInfo.length">
          <a-list-item class="list-item" v-for="item in trendsInfo" :key="item.id">
            <a-list-item-meta>
              <a-avatar slot="avatar" v-if="item.image" :src="$imgBaseUrl + item.image" />
              
              <div slot="title">
                {{item.name}}：
                <span class="hight-light">{{item.dynamicContent}}</span>
              </div>
              <div slot="description">{{$moment(item.createTime).format('YYYY-MM-DD HH:mm:ss')}}</div>
            </a-list-item-meta>
          </a-list-item>
        </a-list>
        <div v-else class="no-data">暂无动态</div>
      </div>
    </div>
  </a-card>
  <div class="comment-html" @click="show" v-if="isShow">留言</div>
  <div
    class="comment"
    v-if="isComment"
    @click="commenttEvt"
  >
    <a-form
      :form="form"
      :label-col="{ span: 10 }"
      :wrapper-col="{ span: 25 }"
      style="width: 80%; display: inline-block"
    >
      <a-form-item label="" class="comment-input">
        <a-textarea
          :rows="4"
          placeholder="请输入您的留言"
          style="border: none; outline: none"
          v-model="form.dynamicContent"
        />
      </a-form-item>
    </a-form>
    <a-button
      type="primary"
      size="small"
      @click="handleSubmit"
      class="comment-btn"
      disabled
      v-if="form.dynamicContent==null"
      >发送
    </a-button>
    <a-button
      type="primary"
      size="small"
      @click="handleSubmit"
      class="comment-btn"
      v-else
      >发送
    </a-button>
  </div>
  </div>
</template>
<script>
import infiniteScroll from "vue-infinite-scroll";
import { getTrends, comment } from "@/httpsAPI/common";
export default {
  name: "Trends",
  directives: { infiniteScroll },
  props: {
    // 页面类型：0阶段 1评审点 2需求，3任务，4求助单
    pageType: {
      type: [Number, String]
    },
    projectId: {
      type: [Number,String]
    },
    taskId: {
      type: [Number,String],
      default: ''
    },
    taskType: {
      type: [Number,String],
      default: ''
    },
  },
  data() {
    return {
      busy: false,
      isShow: true,
      isComment: false,
      trendsInfo: [],
      form: {
        dynamicContent: ""
      },
      tabOptions: [],
      // 0：留言，1：变更记录，2：交付物，3：关联模板（任务详情）,4：关联任务（阶段详情），5：任务资料（任务详情上传、删除资料）6：处理记录（求助单详情）
      dynamicType: ""
    };
  },
  created() {
    // 页面类型：0阶段 1评审点 2需求，3任务，4求助单
    if(this.pageType==0) {
      this.tabOptions = [{
        label: "全部",
        key: "10000",
        value: ""
      },
      {
        label: "留言",
        key: "0",
        value: "0"
      },
      {
        label: "变更记录",
        key: "1",
        value: "1"
      },
      {
        label: "关联记录",
        key: "3",
        value: "3"
      }]
    } else if(this.pageType==1) {
      this.tabOptions = [{
        label: "全部",
        key: "10000",
        value: ""
      },
      {
        label: "留言",
        key: "0",
        value: "0"
      },
      {
        label: "变更记录",
        key: "1",
        value: "1"
      },
      {
        label: "交付物",
        key: "2",
        value: "2"
      }]
    } else if(this.pageType==2) {
      this.tabOptions = [{
        label: "全部",
        key: "10000",
        value: ""
      },
      {
        label: "留言",
        key: "0",
        value: "0"
      },
      {
        label: "变更记录",
        key: "1",
        value: "1"
      }]
    } else if(this.pageType==3) {
      this.tabOptions = [{
        label: "全部",
        key: "10000",
        value: ""
      },
      {
        label: "留言",
        key: "0",
        value: "0"
      },
      {
        label: "变更记录",
        key: "1",
        value: "1"
      },
      {
        label: "任务资料",
        key: "5",
        value: "5"
      }]
    }  else if(this.pageType==4) {
      this.tabOptions = [{
        label: "全部",
        key: "10000",
        value: ""
      },
      {
        label: "留言",
        key: "0",
        value: "0"
      },
      {
        label: "变更记录",
        key: "1",
        value: "1"
      },
      {
        label: "处理记录",
        key: "6",
        value: "6"
      }]
    } 
    this.getTrends()
  },
  methods: {
    getTrends() {
      const {dynamicType, projectId, taskId, taskType} = this
      getTrends({dynamicType, projectId, taskId, taskType}).then((res) => {
        this.trendsInfo = res.data
          console.log(res);
          
      });
    },
    changeTab(e) {
      this.dynamicType = e.target.value
      this.getTrends()
    },
    onClose(){
      this.isComment = false;
      this.isShow = true;
    },
    show() {
      this.isShow = false;
      console.log(this.isShow,"111")
      this.isComment = true;
    },
    commenttEvt() {
     
    },
    handleInfiniteOnLoad() {
      /*
      const data = this.data;
      this.loading = true;
      if (data.length > 14) {
        this.$message.warning('Infinite List loaded all');
        this.busy = true;
        this.loading = false;
        return;
      }
      this.fetchData(res => {
        this.data = data.concat(res.results);
        this.loading = false;
      });
      */
    },
    handleSubmit() {
       const params = {
         dynamicType: 0,
         dynamicContent: this.form.dynamicContent,
         projectId: this.projectId,
         taskId: this.taskId,
         taskType : this.taskType
      }
      comment(params).then((res) => {
          if(res.code === "10000") {
            this.$message.success("留言成功");
            this.getTrends()
            this.form.dynamicContent = ""
          }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.title {
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 16px;
}
.comment-html {
  background: #fff;
  margin-top:50px;
  color: #ddd;
  padding: 10px;
  width: 100%;
  height: 50px;
  overflow-x: hidden;
  box-shadow: 0 0 3px #ccc;
}
.comment {
  position: relative;
  background: #fff;
  width: 100%;
  min-height: 50px;
  max-height: 100px;
  box-shadow: 3px 0 3px #ccc;
  .comment-input textarea {
    &:focus {
      outline: none !important;
      border-color: #fff;
      box-shadow: 0 0 10px #fff;
    }
    margin-top: 2px;
    background: #fff;
  }
}
.demo-infinite-container {
  border-radius: 4px;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
  height: 680px;
}
.demo-loading-container {
  position: absolute;
  bottom: 40px;
  width: 100%;
  text-align: center;
}
.ant-form-item-control-wrapper {
  width: 400px;
}
.comment-btn {
  display: inline-block;
  position: absolute;
  bottom: 7px;
  right: 10px;
}
.comment-top {
  width: 100%;
}
/deep/ .ant-radio-button-wrapper {
  padding: 0 7px !important;
  font-size: 12px !important;
}
/deep/ .ant-list-item-meta-title {
  font-size: 12px;
  font-weight: normal;
  line-height: 15px;
}
/deep/ .ant-list-item-meta-description {
  font-size: 12px;
  font-weight: normal;
  line-height: 15px;
}
.hight-light {
  color: #1890ff;
}
.ant-card-bordered{
  border-bottom: none !important;
}
.no-data {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
