import request from '@/utils/request'

// 求助单分页查询
export function getWorderList (parames) {
  return request({
    url: '/worder/list',
    method: 'post',
    data: parames
  })
}

// 新建求助单
export function addWorder (parames) {
  return request({
    url: '/worder/add',
    method: 'post',
    data: parames
  })
}

// 编辑求助单
export function updateWorder (parames) {
  return request({
    url: '/worder/update',
    method: 'post',
    data: parames
  })
}

// 删除求助单
export function deleteWorder (parames) {
  return request({
    url: '/worder/delete/' + parames.id,
    method: 'post',
    data: {}
  })
}

// 求助单详情
export function detailWorder (parames) {
  return request({
    url: '/worder/detail/' + parames.id,
    method: 'post',
    data: {}
  })
}

// 处理人列表
export function getResolveList (parames) {
  return request({
    url: '/worder/user-list',
    method: 'post',
    data: parames
  })
}


// 求助单处理
export function resolveWorder (parames) {
  return request({
    url: '/worder/resolve',
    method: 'post',
    data: parames
  })
}

// 求助单标记是否采纳
export function endWorder (parames) {
  return request({
    url: '/worder/end',
    method: 'post',
    data: parames
  })
}


